
@media print {
    .do-not-print{
        display:none
    }

    .print-only {
        display: block;
    }
}

.dashboard-main-header {
    height: 5vh;
    padding: 0vh;
    margin-bottom: 1vh;
}
.dashboard-main-content-row {
    display: flex;
    padding-top: 1vh;
    padding-bottom: 1vh;
    min-height: 88vh;
}

.dashboard-main-content {
    width: 95%;
    padding-left: 2%;
    padding-top: 2%;
    /* background-color: beige; */
}

.dashboard-main-footer {
    height: 5vh;
    padding: 0vh;
    background-color: rgb(200, 20, 23);
}
.dashboard-content-area {
    padding: 2rem;
}