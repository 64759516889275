@media print {
    .pagebreak { page-break-before: always; }
}
.printable-section {
    page-break-inside: avoid;
  } 
  @media print {
    .printable-section {
      page-break-inside: avoid;
    }
    @page {
      size: A4;
    }
    
  }

.MuiGrid-rootMuiGrid-itemMuiGrid-grid-xs-12css-1idn90j-MuiGrid-root{
  padding: 100px !important;
}

td{
  font-size: 16px!important;
}