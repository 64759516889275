.table-header-tg { 
    background-color:#70ad47!important   ;
    text-align: 'justify!important';

}

.table-header-label {

    font-family: 'Arial, sans-serif';
    font-size: '18px!important';
    text-align: 'justify!important';
    font-weight: bold;
    /* background-color: #6fb742!important ; */
}

.color-row {
    background-color:#e2efd9!important ;
    text-align: 'justify!important';
    /* font-size: '10px!important'; */
}

  /* .tableContainer {
    page-break-before: auto;
} */
/* .second-table{
    margin-top: 50px;
}
.tableContainer, .tableContainer table, .tableContainer tbody, .tableContainer tr, .tableContainer td {
    page-break-inside: avoid;
}

@media print {
    .tableContainer {
        page-break-before:inherit;
    }
} */
